import React from "react";
import { t, Trans } from "@lingui/macro";
import styled from "@emotion/styled";
import { Button, Stack, Typography, Box } from "@mui/material";
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n";
import useIsLanding from "../../hooks/useIsLandingHook";
import NewLayout from "../../components/NewLayout";
import LandingLayout from "../../components/landings/LandingLayout";
import Container from "../../components/Container";
import DownloadPdfButton from "../../components/DownloadPdfButton";

const doc = {
  es: "https://app.allfunds.com/docs/cms/Allfunds_Internal_Information_System_Reporting_Channel_Policy_and_Procedure_202409_ES_dfef45381b.pdf",
  en: "https://app.allfunds.com/docs/cms/Allfunds_Internal_Information_System_Reporting_Channel_Policy_and_Procedure_202409_EN_f297761014.pdf",
  it: "https://app.allfunds.com/docs/cms/Allfunds_Internal_Information_System_Reporting_Channel_Policy_and_Procedure_092024_ITA_clean_500c875d1e.pdf",
};

function ModalReportingChannel() {
  const { locale } = useLocalization();
  const isLanding = useIsLanding();
  const Layout = isLanding ? LandingLayout : NewLayout;

  return (
    <Layout
      {...(isLanding ? {
        seoTitle: t`Reporting Channel`,
      } : {
          byDefault: true,
          apps: [{ label: <Trans>Reporting Channel</Trans>, path: "/legal-advice/reporting-channel" }],
        })}
      >
      <Container pb={{ xs: 4, sm: 8 }}>
        <Typography sx={{ fontSize: 36, fontWeight: 700 }}>
          <Trans>Internal Information System & Reporting Channel</Trans>
        </Typography>
        <Stack spacing={2} mt={2} alignItems="center">
          <Typography>
            <Trans>
              Allfunds is committed to regulatory compliance and ethical behaviour
              in the conduct of its business. The Internal Information System &
              Reporting Channel has been established to effectively protect
              reporting persons and implement applicable regulations.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              The Reporting Channel can be used by Allfunds’ employees, former
              workers, potential employees involved in recruitment processes,
              trainees, independent professionals rendering services to Allfunds,
              persons working under the supervision and direction of contractors,
              subcontractors and providers and also directors and shareholders of
              any Group company and any other person if expressly provided by
              applicable local legislation. Through this channel, they may report
              any irregularities they observe, especially those that might
              constitute improper practices, law breaches, or allegedly illegal
              acts.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              The Allfunds Internal Information Systema & Reporting Channel Policy
              sets out the general principles governing the information system and
              reporting persons’ protection. Reporting persons shall act in good
              faith without making knowingly false, malicious or frivolous
              accusations. The Reporting Channel operates under the principle of
              strict confidentiality of all information provided, and Allfunds
              will ensure the confidentiality of the reporting person’s identity
              if communicated. Likewise, anonymous reports may also be filed and
              will be processed in the same way.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              Allfunds will handle reports with absolute impartiality and ensuring
              the absence of prejudice for reporting persons. Allfunds will not
              tolerate any form of retaliation (including the simple threat or
              attempted retaliation) against reporting persons. Retaliation is
              understood as any act or omission that results in the reporting
              persons’ unfavourable treatment or that place the Reporting Persons
              in a disadvantageous position in the professional sphere based only
              on their status as reporting persons. The foregoing will not prevent
              the adoption of disciplinary actions in accordance with applicable
              rules and regulations in the event of a deliberately false, wrong or
              misleading report submitted in bad faith.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              In any case, please note that you may always contact directly the
              European Union institutions, bodies, offices or agencies created
              locally for these purposes in accordance with the provisions of the
              applicable legislation
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              Please note that the Reporting Channel is not intended to apply to
              commercial dissatisfaction nor general inquires. For
            </Trans>{" "}
            <Link to="/contact/">
              <Trans>general inquiries</Trans>
            </Link>{" "}
            <Trans>and</Trans>{" "}
            <Link to="/client-care/">
              <Trans>clients’ complaints</Trans>
            </Link>
            {", "}
            <Trans>please click on the links</Trans>.
          </Typography>
          <Box sx={{ display: "flex", gap: 3, width: "100%" }} mt="36px !important">
            <DownloadPdfButton
              path={doc[locale] || doc.en}
              variant="secondary-outline"
              v2
              title={
                <Typography>
                  <Trans>
                    Internal Information System & Reporting Channel policy
                  </Trans>
                </Typography>
          }
        />
            <Button
              variant="secondary-dark"
              onClick={() =>
            window.open(
              "https://allfunds.globalsuitesolutions.com/allfundsreportingchannel",
            )}
              sx={{
                height: "36px",
              }}
        >
              <Typography color="white !important" variant="small" sx={{ fontWeight: 600 }}>
                <Trans>Go to Reporting Channel</Trans>
              </Typography>
            </Button>
          </Box>
        </Stack>
      </Container>
    </Layout>
  );
}

const Link = styled(LocalizedLink)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.palette.primary.main};
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
`;

export default ModalReportingChannel;
